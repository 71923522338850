import React, { useState } from "react";
import { Container, Form, Button, Row, Col } from "react-bootstrap";
import Toastr from "../../../components/toastr";

const ContactForm = () => {
  const [toastr, setToastr] = useState<APP.ToastrForm>({});
  const [visible, setVisible] = useState(false);
  const [form, setForm] = useState<APP.ContactForm>({ found: "friends" });

  const handleSubmit = (event: any) => {
    event.preventDefault();
    // console.table(form);
    if (form.email && form.fullName) {
      setToastr({
        title: "Thanks for reaching out on us",
        message: "We'll contact you soon...",
      });
      setVisible(true);
      // TODO: call api to save form params
    }
  };
  return (
    <Container fluid className="py-5">
      <Container>
        <Form id="form" onSubmit={handleSubmit}>
          <Form.Group as={Row} className="mb-3" controlId="fullName">
            <Form.Label column sm="3">
              Full Name:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                placeholder="James Bond"
                value={form.fullName}
                onChange={(e) => setForm({ ...form, fullName: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="company">
            <Form.Label column sm="3">
              Company:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="text"
                placeholder="MI6"
                value={form.company}
                onChange={(e) => setForm({ ...form, company: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="email">
            <Form.Label column sm="3">
              Email address:
            </Form.Label>
            <Col sm="9">
              <Form.Control
                type="email"
                placeholder="abc__jamesbond@email.com"
                value={form.email}
                onChange={(e) => setForm({ ...form, email: e.target.value })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="found">
            <Form.Label column sm="3">
              How Do You Find Out About us
            </Form.Label>
            <Col sm="9">
              <Form.Select
                value={form.found}
                onChange={(e) => setForm({ ...form, found: e.target.value })}
              >
                <option value="friends">Friends</option>
                <option value="colleagues">Colleagues</option>
                <option value="searchEngine">Search Engine</option>
                <option value="email">Email</option>
                <option value="phone">Phone</option>
                <option value="advertisement">Advertisement</option>
                <option value="dropBy">Drop by</option>
                <option value="other">Other</option>
              </Form.Select>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="help">
            <Form.Label column sm="3">
              What Do You Wish To Know
            </Form.Label>
            <Col sm="9">
              <Form.Control
                as="textarea"
                placeholder="how can we help you?"
                rows={3}
                value={form.help}
                onChange={(e) => setForm({ ...form, help: e.target.value })}
              />
            </Col>
          </Form.Group>
          <div className="d-grid">
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </div>
        </Form>
      </Container>

      <Toastr
        title={toastr.title}
        message={toastr.message}
        visible={visible}
        setVisible={setVisible}
      />
    </Container>
  );
};

export default ContactForm;
