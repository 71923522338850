import React, { Fragment } from "react";
import Carousel from "./components/carousel";
import Banner from './components/banner'
import Ladder from "./components/ladder";
import Cubic from './components/cubic'

export default function PageHome() {
  return (
    <Fragment>
      <Carousel />
      <Banner />
      <Ladder />
      <Cubic />
    </Fragment>
  );
}
