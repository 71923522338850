import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./footer.scss";

const Tile = ({ title, arr }: { title: string; arr: CONF.ProviderLink[] }) => (
  <div className="footer-tile">
    <h5 className="footer-tile__header mb-2">{title}</h5>
    {arr.map((item) => (
      <Link className="footer-tile__item mb-2" key={item.title} to={item.href}>
        {item.title}
      </Link>
    ))}
  </div>
);

export default function ComponentFooter() {
  const data = [
    {
      title: "Features",
      arr: [
        { title: "PrivateCloud", href: "/" },
        { title: "Security", href: "/" },
        { title: "Management", href: "/" },
      ],
    },
    {
      title: "Company",
      arr: [
        { title: "About", href: "/contact" },
        { title: "Blog", href: "/" },
        { title: "Careers", href: "/" },
      ],
    },
    {
      title: "Support",
      arr: [
        { title: "FAQ", href: "/contact" },
        { title: "Contact", href: "/contact" },
        { title: "Pricing", href: "/" },
      ],
    },
  ];
  return (
    <Container fluid className="bg-gray">
      <Container className="py-5">
        <Row>
          {data.map((item) => (
            <Col xs={12} sm={6} md={4} key={item.title}>
              <Tile title={item.title} arr={item.arr} />
            </Col>
          ))}
        </Row>
        <Row>
          <Col></Col>
        </Row>
      </Container>
      <Container>
        <div className="flex justify-between">
          <p>
            Copyright &copy; {new Date().getFullYear()} Synth Proto | All Rights
            Reserved
          </p>
          <div>
            <Link to="/contact" className="link ps-2">
              Privacy
            </Link>
            <Link to="/contact" className="link ps-2">
              Terms Of Use
            </Link>
          </div>
        </div>
      </Container>
    </Container>
  );
}
