import React from "react";
import { Image } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import "./cubic.scss";

const Cubic = () => (
  <section className="sectionContainer mb-5" id="solution">
    <ScrollAnimation animateIn="fadeInUp" offset={0} animateOnce={true}>
      <h4 className="bannerText mb-4 pt-4 pb-4 text-center">
        Fully Managed Open Source Large Language Model running in Your Own Cloud
      </h4>
    </ScrollAnimation>
    <div className="container">
      <div className="row">
        <div className="col-md-4 pt-5">
          <ScrollAnimation animateIn="fadeInLeft" offset={0} animateOnce={true}>
            <div className="block pt-4 pb-4 text-right">
              <h3>LLM with Uncompromised Security</h3>
              <p className="text-muted">
                Open Source LLM running in Your Own Virtual Private Cloud. With
                all data and communications staying in your own private network,
                sleep soundly knowing that your business-critical information is
                safe.
              </p>
            </div>
            <div className="block pt-4 pb-4 text-right">
              <h3>Fully Managed Solution</h3>
              <p className="text-muted">
                Our fully managed solution takes care of AI model hosting,
                allowing you to benefit from LLM without managing AI
                infrastructures.
              </p>
            </div>
          </ScrollAnimation>
        </div>
        <div className="col-md-4 text-center">
          <ScrollAnimation animateIn="fadeInUp" offset={0} animateOnce={true}>
            <Image width="80%" src="/images/cubic.png" alt="img" />
          </ScrollAnimation>
        </div>
        <div className="col-md-4 pt-5">
          <ScrollAnimation
            animateIn="fadeInRight"
            offset={0}
            animateOnce={true}
          >
            <div className="block pt-4 pb-4">
              <h3>Adapt LLM to Your Own Data</h3>
              <p className="text-muted">
                With secured LLM, fine-tune LLM without worrying about your own
                sensitive data, with the help from us.
              </p>
            </div>
            <div className="block pt-4 pb-4">
              <h3>Data and Security Compliance</h3>
              <p className="text-muted">
                Audited and Logged interaction with LLM Model. Meets all
                security compliance with a full audit trail and history backup.
              </p>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  </section>
);

export default Cubic;
