import React from "react";
import { Image, Container } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import './banner.scss'

const list = [
  { label: "Fully Managed Solution", src: "/images/icon_1.png" },
  { label: "Adapt LLM to Your Own Data", src: "/images/icon_2.png" },
  { label: "Data and Security Compliance", src: "/images/icon_3.png" },
];

const Banner = () => (
  <Container className="py-5" fluid>
    <Container >
      <ScrollAnimation animateIn="fadeInUp" offset={0} animateOnce={true}>
        <h2 className="text-center pb-5">Fully Managed Open Source Large Language Model</h2>
      </ScrollAnimation>
      <div className="row slogan">
        {list.map((item) => (
          <div
            key={item.src}
            className="col-md-4 col-sm-6 col-xs-12"
          >
            <ScrollAnimation animateIn="fadeInUp" offset={0} animateOnce={true}>
              <div className="sloganItem text-center">
                <div className="sloganIconWrapper mb-4">
                  <div className={"sloganIcon"}>
                    <Image
                      width="100%"
                      height="100%"
                      src={item.src}
                      alt="widget"
                    />
                  </div>
                </div>
                <h4>{item.label}</h4>
              </div>
            </ScrollAnimation>
          </div>
        ))}
      </div>
    </Container>
  </Container>
);

export default Banner;
