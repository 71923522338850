import React, { useState } from "react";
// import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import classNames from "classnames";
import "./nav.scss";

export default function ComponentNav() {
  const [toggle, setToggle] = useState(false);
  let location = useLocation();
  const onToggle = (expanded: boolean) => {
    if (expanded) {
      window.document.body.setAttribute("class", "offcanvas-page");
    } else {
      window.document.body.removeAttribute("class");
    }
    setToggle(expanded);
  };
  const isHome = location.pathname === "/";
  return (
    <div
      className={classNames("navBg", !isHome ? "active" : "normal", "w-100")}
    >
      <Navbar
        expand="lg"
        className={classNames("container", "position-relative")}
        // bg={toggle || isHome ? "transparent" : "dark"}
        bg="transparent"
        expanded={toggle}
        onToggle={onToggle}
      >
        <Container>
          <Navbar.Brand href="/">Synth Proto</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link href="/" active={location.pathname === "/"}>
                Home
              </Nav.Link>
              <Nav.Link
                href="/contact"
                active={location.pathname === "/contact"}
              >
                Contact Us
              </Nav.Link>
            </Nav>
            {location.pathname !== "/contact" && (
              <Nav className="justify-content-end">
                <Nav.Link href="/contact" className="button">
                  Booke a demo
                </Nav.Link>
              </Nav>
            )}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
