import React from "react";
import Toast from "react-bootstrap/Toast";
import "./toastr.scss";

const Toastr = ({
  title,
  message,
  visible,
  setVisible,
}: {
  variant?: string;
  title?: string;
  message?: string;
  visible: boolean;
  setVisible: (bool: boolean) => void;
}) => (
  <Toast
    className="toastr"
    onClose={() => setVisible(false)}
    show={visible}
    delay={3000}
    autohide
  >
    {title && (
      <Toast.Header>
        <strong className="me-auto">{title}</strong>
        <small>just now</small>
      </Toast.Header>
    )}
    <Toast.Body>{message}</Toast.Body>
  </Toast>
);

export default Toastr;
