import React from "react";
import { Outlet } from "react-router-dom";
import ComponentNav from "../../components/nav";
import ComponentFooter from "../../components/footer";
import './visiter.scss'

export default function VisiterLayout() {
  return (
    <>
      <ComponentNav />
      <main className="layout visiter">
        <Outlet />
      </main>
      <ComponentFooter />
    </>
  );
}
