import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./App.scss";
import ErrorPage from "./components/error";
import Spinner from "./components/spinner";
import VisiterLayout from "./pages/layout/visiter";
import PageHome from "./pages/home";
import PageContact from "./pages/contact";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <VisiterLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <PageHome />,
        },
        {
          path: "contact",
          element: <PageContact />,
        },
      ],
    },
  ]);
  return <RouterProvider router={router} fallbackElement={<Spinner />} />;
}

export default App;
