import React from "react";
import { Carousel, Image, Button } from "react-bootstrap";
import { conf } from "../../../utils";
import "./carousel.scss";

const CarouselBanner = () => (
  <div className="carousel">
    <Carousel
      indicators={false}
      fade={true}
      controls={false}
      className="h-100 w-100"
    >
      <Carousel.Item className="h-100">
        <div className="mask" />
        <Image
          className="carousel-image d-block h-100"
          src={conf.CDN_HOST + "/images/2_slide.jpg"}
          alt={conf.IMG_ALT}
        />
        <Carousel.Caption>
          <h3>
            SynthProto: Secured, Fully Managed Open Source Large Language Model
            running in Your Own Cloud.
          </h3>
          <Button variant="primary" href="/contact">Book a demo</Button>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  </div>
);

export default CarouselBanner;
