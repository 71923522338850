
import pkg from "../../package.json";

export const conf = {
    APP_NAME: 'Synth Proto',
    API_HOST: process.env.REACT_APP_API_HOST,
    API_PREFIX: process.env.REACT_APP_API_PREFIX,
    CDN_HOST: process.env.REACT_APP_CDN_HOST,
    GTA_HOST: process.env.REACT_APP_API_HOST,
    FRONT_HOST: process.env.REACT_APP_FRONT_HOST,
    FRONT_SOURCE: process.env.REACT_APP_FRONT_SOURCE,
    CACHE_DB_NAME: process.env.REACT_APP_CACHE_DB_NAME,
    CACHE_VERSION: process.env.REACT_APP_CACHE_VERSION,
    TOKEN: localStorage.getItem("sp-token"),
    LANG: localStorage.getItem("lang"),
    VERSION: pkg.version,
    IMG_ALT: 'Image - Synth Proto',
};

export const trunc = (name: string, length = 18): string => {
    if (name.length > length) {
        return name.substring(0, length) + ".."
    }
    return name
}

export const randomID = (): string => {
    return Date.now().toString(32) + Math.random().toString(32).substring(2)
}