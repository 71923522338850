import React from "react";
import { Container } from "react-bootstrap";
import './header.scss'

const Header = () => (
  <Container fluid className="header text-center">
    <Container>
      <h1>Contact us</h1>
      <p>
        Connect with us to learn about opportunities to optimize your data
        cloud!
      </p>
    </Container>
  </Container>
);

export default Header;
