import React from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Row, Col } from "react-bootstrap";
import './ladder.scss'

const Ladder = () => (
  <section
    className="sectionContainer container-fluid bg-gray py-5"
    id="accommondation"
  >
    <div className="container">
      <Row className="mb-4">
        <Col
          xs={{ offset: 0, span: 12 }}
          sm={{ offset: 1, span: 8 }}
          md={{ offset: 2, span: 6 }}
        >
          <ScrollAnimation
            animateIn="slideInRight"
            offset={0}
            animateOnce={true}
          >
            <div className="textPanel">LLM with Uncompromised Security</div>
          </ScrollAnimation>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col
          xs={{ offset: 0, span: 12 }}
          sm={{ offset: 2, span: 8 }}
          md={{ offset: 4, span: 6 }}
        >
          <ScrollAnimation
            animateIn="slideInRight"
            offset={0}
            animateOnce={true}
          >
            <div className="textPanel">Fully Managed Solution</div>
          </ScrollAnimation>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col
          xs={{ offset: 0, span: 12 }}
          sm={{ offset: 3, span: 8 }}
          md={{ offset: 6, span: 6 }}
        >
          <ScrollAnimation
            animateIn="slideInRight"
            offset={0}
            animateOnce={true}
          >
            <div className="textPanel">Adapt LLM to Your Own Data</div>
          </ScrollAnimation>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col
          xs={{ offset: 0, span: 12 }}
          sm={{ offset: 4, span: 8 }}
          md={{ offset: 8, span: 4 }}
        >
          <ScrollAnimation
            animateIn="slideInRight"
            offset={0}
            animateOnce={true}
          >
            <div className="textPanel">Data and Security Compliance</div>
          </ScrollAnimation>
        </Col>
      </Row>
    </div>
    <div className="container">
      <ScrollAnimation animateIn="fadeInUp" offset={0} animateOnce={true}>
        <h2 className="mt-5 mb-0">SynthProto: Secured</h2>
      </ScrollAnimation>
    </div>
  </section>
);
export default Ladder;
